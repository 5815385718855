//
// Forms
// --------------------------------------------------




form.contact .input-group-addon{
    width:105px;
}

@media screen and (max-width: @screen-xs-max) {
    form.contact .input-group-addon{
        width:40px;
    }
}

form.contact{
    max-width: 600px;
}

.contact{
    .input-group-addon{
        border-color:rgba(0,0,0,0.30);
    }
}

.checkbox-rounded [type="checkbox"][class*='filled-in']+label:after {
    border-radius: 50%;

}