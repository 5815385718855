
// Headings
// -------------------------



html, body {
    width:100%;
    height:100%;
    margin:0;
    font-size: @font-size-base;
    line-height: 1.5em;
    color: @text-color;
    background-color: @body-bg;
    font-family: @font-family-base;
}
.main{
    padding-top:100px;
}

h1 {
    padding-top: 1.2em;
    padding-bottom: 0.75em;
    color: @brand-red;
    font-size: @font-size-h1;
}

.upper{
    text-transform: uppercase;
}
.lower{
    text-transform: lowercase;
}
.capitalize{
    text-transform: capitalize;
}

h2 {
    color:@brand-red;
    text-align: center;
    margin-top:1.5em;
    margin-bottom: 1em;
    font-size: @font-size-h2;
    font-weight: lighter;
}

h3 {
    font-size: @font-size-h3;
}

h4 {
    color:#353534;
    text-align: center;
    font-size: @font-size-h4;
}


html.js .jshidden {
    display: none;
}


img.grayscale {
    -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
    filter: grayscale(100%);
}

a.text-muted {
    &, &:hover, &:focus {
        color: @text-muted;
    }
}

.text-muted {
    a {
        &, &:hover, &:focus {
            color: @text-muted;
            font-weight: 700;
        }
    }
}


.eu-cookies{position:fixed;left:0;bottom:0px;width:100%;padding:2px;text-align:center;color:#dadbcb;background-color:#353534;z-index:1000}

.color-red{
    color: @brand-red;
}
.color-black{
    color:@brand-black;
}
.color-blue{
    color:@brand-blue;
}
.color-white{
    color: @brand-white;
}
.color-gray{
    color: @brand-gray;
}
.color-gray-light{
    color:@brand-gray-light;
}
.color-gray-lighter{
    color:@brand-gray-lighter;
}
.bg-red{
    background-color: @brand-red;
}
.bg-black{
    background-color:@brand-black;
}
.bg-blue{
    background-color:@brand-blue;
}
.bg-white{
    background-color: @brand-white;
}
.bg-gray{
    background-color: @brand-gray;
}
.bg-gray-light{
    background-color:@brand-gray-light;
}
.bg-gray-lighter{
    background-color:@brand-gray-lighter;
}

.bg-transparent-gray{
    background-color: rgba(0,0,0,0.05);
}

@media screen and (min-width: @screen-sm){
    .sm-height-240{
        height:240px;
    }
}

hr.line-dotted{
    border: 4px dotted @brand-gray;
    border-style: none none dotted;
    color: #fff;
    background-color: #fff;
    width: 65%;
    max-width: 430px;
}

.line-with-text{
    width:100%;
    text-align:center;
    hr{
        margin-top:16px;
        margin-bottom:3px;
        border: 2px dotted @brand-red;
        border-style: none none dashed;
        color: #fff;
        background-color: #fff;
        width: 15%;
        max-width: 44px;
        display: inline-block;
    }
    .text{
        color:@brand-red;
        padding:9px;
    }
}

.text-normal{
    font-size: 1em;

}
.text-biger{
    font-size: 1.2em;
    line-height: 2em;
}
.text-bigest{
    font-size: 2.25em;
}

#mainNavbar{
    padding:0;

    #mainContainer{
        background-color: rgba(255,255,255,0.9);
        height:90px;
        margin-top:0;
        margin-bottom: 0;
    }
}

#navbarLine{
    height:14px;
    width:100%;
    background-image: url('../images/line.png');
    background-repeat: repeat-x;
    background-position: bottom;
}

#name{
    font-size:1.3em;
    font-weight: bold;
    float:right;
    text-align: right;
    color:@brand-red;
}

@media screen and (min-width: 992px){
    #name{
        margin-top:-40px;
    }
}

#mainContainer{
    button{
        padding:12px;
        margin-top:17px
    }
}

#mainMenu{
    a{
        font-size: 1.2em;
        color: @brand-blue;
        font-weight: bold;
    }
}
.property-icon{
    display:inline-block;
}
.property-text{
    display: none;
}

@media screen and (min-width: @screen-md){
    .property-icon{
        display:none;
    }
    .property-text{
        display: inline;
    }
    #mainMenu{
        position:absolute;
        top:44px;
        right:0;
    }
}

.adminPanel{
    color:@brand-white;
    background-color:rgba(246,246,255,0.9);
}

.menu-divider{
    position: relative;
    top:8px;
    width:9px;
    height:27px;
    background-image: url('../images/divider.png');
}

@media screen and (max-width: @screen-sm-max){
    .menu-divider{
        width:0;
        height:0;
        display:none;
        background: none;
    }
}

#logo{
    height:64px;
    margin-top:0px;
}

.photo-block{
    float:right;
    text-align: center;
}

@media screen and (max-width: @screen-xs-max) {
    #logo{
        height:50px;
    }
    .xs-hide{
        display:none;
    }
    .photo-block{
        width:100%;
    }
}

.siluet{
    background-image: url("../images/siluet.png");
    background-repeat: no-repeat;
    background-origin: content-box;
    background-position: center;
    width:100%;
    height:220px;
    padding-top:28px;
    clear:both;
}

.carousel-inner img {

    width: 100%;
    height: 100%;
}

.message-text{
    min-height: 2.8em;
    margin-bottom: 0;
}

.message-note{
    margin-bottom: 0;
    border-radius: 10px;
    padding: 5px;
    padding-left: 9px;
}

.profile-photo{
    max-width: 300px;
    min-width: 100px;
    width:25%;
    margin:15px;
}

.property{
    .card-header{

    }
    .card-body{

    }
    .card-footer{

    }

}

.article-card{
    width: 100%;
    padding:5px;
    float:none;
    clear:both;

    .badge{
        padding-top:6px;
        padding-bottom:4px;
        padding-left: 8px;
        padding-right: 8px;
        margin-left:10px;
    }
}

.article-card-img{
    width:30%;
    padding:8px;
    img{
        width:100%;
        max-width: 300px;
    }
}
.article-card-body{
    width:70%;
    padding:8px;
}

@media screen and (min-width: @screen-md){
    .article-card-body{
        padding-left:16px;
    }
}

.property-card{
    width: 100%;
    padding:5px;
    float:none;
    clear:both;
}

.property-card-img{
    width:30%;
    padding:8px;
    img{
        width:100%;
        max-width: 300px;
    }
}
.property-card-body{
    width:70%;
    padding:8px;
}


.article-introduction{
    margin-top:60px;
}

#mainMenu{
    background-color: rgba(255,255,255,.9);
    padding-left:30px;
}
#mainMenu.show{
    border-bottom: solid @brand-gray-light;
}

#navbarPanels{
    width: 100%;
    margin:0;
    padding:0;
    background-color: rgba(255,255,255,.9);
}

.carousel-fade .carousel-item {
    opacity: 0;
    transition-duration: .6s;
    transition-property: opacity;
}

.carousel-fade  .carousel-item.active,
.carousel-fade  .carousel-item-next.carousel-item-left,
.carousel-fade  .carousel-item-prev.carousel-item-right {
    opacity: 1;
}

.carousel-fade .active.carousel-item-left,
.carousel-fade  .active.carousel-item-right {
    opacity: 0;
}

.carousel-fade  .carousel-item-next,
.carousel-fade .carousel-item-prev,
.carousel-fade .carousel-item.active,
.carousel-fade .active.carousel-item-left,
.carousel-fade  .active.carousel-item-prev {
    transform: translateX(0);
    transform: translate3d(0, 0, 0);
}

.reference-icon{
    max-width: 162px;
}

