//
// Buttons
// --------------------------------------------------



.btn-square{
    padding-right:0.7em;
    padding-left:0.7em;
    height:3.2em !important;
    padding-top: 0.74em;
    padding-bottom: 0.5em;
    background: transparent !important;
    border-radius: 15px;
    -moz-border-radius: 15px;
    -webkit-border-radius: 15px;
    border: 0px solid #000000;
}

.btn-success{
    color:#f7f7f7;
    background-color: #82bd16;
}
.btn-success:hover,.btn-success:active,.btn-success:focus{
    color:#50504d;
    background-color: #bfe871;
}

.btn-link{
    text-transform: none;
    color:#ef5b56;
    padding-right: 0;
    padding-left: 0;
}

.btn-link:hover,.btn-link:active,btn-link:focus{
    .btn-link;
    color:#353534;
}

.btn-white{
    color:#353534 !important;
    background-color:#f7f7f7 !important;
}
.btn-white:hover,.btn-white:active,btn-white:focus{
    color:#dadbcb !important;
    background-color:#353534 !important;
}


.btn-gray{
    color:#dadbcb !important;
    background-color:#353534 !important;
}
.btn-gray:hover,.btn-gray:active,btn-gray:focus{
    color:#f7f7f7 !important;
    background-color:#50504d !important;
}

.btn-blue{
    color:@brand-white;
    background-color:@brand-blue;
}
.btn-blue:hover{
    color:@brand-white;
    background-color:@brand-blue-light;
}

.btn-red{
    color:@brand-white;
    background-color:@brand-red;
}
.btn-red:hover{
    color:@brand-white;
    background-color:@brand-red-dark;
}

.btn-toolbar,.btn-toolbar:hover,.btn-toolbar:active,.btn-toolbar:focus{
    border-radius: 0;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    border: 0px solid #000000;
    background-color: transparent !important;
    padding-right: 0;
    padding-left: 0;
}

.btn-toolbar-edit,.btn-toolbar-edit:hover,.btn-toolbar-edit:active,.btn-toolbar-edit:focus{
    .btn-toolbar;
}
.btn-toolbar-delete,.btn-toolbar-delete:hover,.btn-toolbar-delete:active,.btn-toolbar-delete:focus{
    .btn-toolbar;
}
.btn-toolbar-edit{
    color:@brand-primary !important;
}
.btn-toolbar-edit:hover,.btn-toolbar-edit:active,.btn-toolbar-edit:focus{
    color:darken(@brand-primary, 15%) !important;
}
.btn-toolbar-delete{
    color:@brand-red !important;
}
.btn-toolbar-delete:hover,.btn-toolbar-delete:active,.btn-toolbar-delete:focus{
    color:darken(@brand-red, 15%) !important;
}

.btn-share{
    .btn-blue;
    text-align: center;
    vertical-align: middle;
        width: 30px;
        height: 30px;

}

.btn-link.color-white:hover,.btn-link.color-white:active,.btn-link.color-white:focus{
    color:@brand-white;
}